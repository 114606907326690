import axios from 'axios';
import {useState, useEffect} from 'react';
import {useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';


import EditIcon from '@mui/icons-material/Edit'; 

import Loading from '../Loading';
import Error from '../Error';

import { useSessionContext } from '../../contexts/SessionContext';


export default function ReelsLoadList(props){
    console.debug("RTS Break ReelsLoadList");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();
    const history = useNavigate();
    const location = useLocation();
    const data = location.state.data;

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [repoInspections, setRepoInspections] = useState([]);

    function fetchData(){

        let apiObj = data;

        let apiURL = apiRoot + "/Reels/GetReelLoads";
        axios
        .post(
            apiURL,
            apiObj,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token}}
        )
        .then(response => {
            if (response.data) {
                setRepoInspections(response.data);
                setIsLoading(false);
            }
        })
        .catch(error => {
            console.log("RTS Data Error", error);
            setHasError(true);
        });
    }


    useEffect(() => {
        fetchData();
    }, []); 


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }

    const columns = [
        {
            field: 'soldToCustomerName', 
            headerName: "Sold To Customer", 
            description:"Sold To Customer Name", 
            flex:4,
            valueGetter: (cellValues) => {
                return cellValues.row.soldToCustomer.name
            },
        },    
        {
            field: 'shipDate', 
            headerName: "Ship Date", 
            description:"Date Shipped", 
            flex:2,           
            valueFormatter: (parms) => {
                if(parms.value == null){
                    return '';
                }

                return (
                    format(parseISO(parms.value), "MM-dd-yyyy")
                );
            } 
        },
        {
            field: 'returnedDate', 
            headerName: "Return Date", 
            description:"Date Returned", 
            flex:2,           
            valueFormatter: (parms) => {
                if(parms.value == null){
                    return '';
                }

                return (
                    format(parseISO(parms.value), "MM-dd-yyyy")
                );
            } 
        }                    

    ];

    return (
        <Box id="pageBox" sx={{width:'100%'}}>
            <DataGridPro 
                autoHeight 
                pagination 
                components={{Toolbar: GridToolbar}} 
                pageSize={50} 
                rows={repoInspections} 
                columns={columns} 
                getRowId={row => row.idGuid} 
            />
        </Box>
    );
}
