import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';

import EditIcon from '@mui/icons-material/Edit'; 

export default function LoadsList(props){
    //console.debug("RTS Break LoadsList");

    const history = useNavigate();

    function handleClick(e, cellValues){
        history("/LoadsEdit", {state:{data:cellValues.row, mode:1}});
    }

    const columns = [
        {
            field: '',
            description:"Edit Load", 
            flex:1,
            maxWidth:60,
            renderCell: (cellValues) => {
                return (
                    <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                        <Box sx={{display:'flex'}}>
                            <EditIcon />
                        </Box>
                    </Link>
                );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },
        {
            field: 'soldToCustomerName', 
            headerName: "Sold To Customer", 
            description:"Sold To Customer Name", 
            flex:4,
        },
        {
            field: 'soldToCustomerIdSap', 
            headerName: "Sold To Id", 
            description:"Sold To Customer Id", 
            flex:3,
            hide:true
        },
        {
            field: 'assignedToCustomerName', 
            headerName: "Assigned Customer", 
            description:"Assgined Customer Name", 
            flex:4,
            hide:true
        },
        {
            field: 'assignedToCustomerIdSap', 
            headerName: "Assigned Id", 
            description:"Assgined Customer Id", 
            flex:3,
            hide:true
        },        
        {
            field: 'productName', 
            headerName: "Product", 
            description:"Product Name", 
            flex:4,
        },          
        {
            field: 'reel', 
            headerName: "Reel", 
            description:"Reel Serial Number", 
            flex:3,
        },
        {
            field: 'tracker', 
            headerName: "Tracker", 
            description:"Tracker ESN", 
            flex:3,
        },
        {
            field: 'shipDate', 
            headerName: "Ship Date", 
            description:"Load Ship Date", 
            flex:2,
            valueFormatter: (parms) => {
                if(parms.value == null){
                    return '';
                }

                return (
                    format(parseISO(parms.value), "MM-dd-yyyy")
                );
            } 
        },                           
        {
            field: 'status', 
            headerName: "Status", 
            description:"Load Status", 
            flex:3,
            renderCell: (cellValues) => {
                switch(cellValues.value){
                    case 1:
                        return ('Active (1)');
                        break;
                    case 25:
                        return ('Marked for Pickup (25)');
                        break;
                    case 50:
                        return ('On Pickup Request (50)');
                        break;
                    case 75:
                        return ('Submitted for Pickup (75)');
                        break;
                    case 100:
                        return ('Returned (100)');
                        break;
                    default:
                        return ('Unknown');
                        break;
                }
            },             
        },                           
        {
            field: 'customerPo', 
            headerName: "Customer PO", 
            description:"Customer PO", 
            flex:3,
            hide:true
        },
        {
            field: 'idSapOrder', 
            headerName: "SAP Order", 
            description:"SAP Order", 
            flex:3,
            hide:true
        },
        {
            field: 'idSapBol', 
            headerName: "BOL", 
            description:"Bill of Lading", 
            flex:3,
            hide:true
        },
        {
            field: 'idSapPackingList', 
            headerName: "Packing List", 
            description:"Packing List", 
            flex:3,
            hide:true
        },
        {
            field: 'idSapBatchNo', 
            headerName: "Batch No.", 
            description:"Batch Number", 
            flex:3,
            hide:true
        }          


    ];

    return (
        <Box id="pageBox" sx={{width:'100%'}}>
            <DataGridPro 
                autoHeight 
                pagination 
                components={{Toolbar: GridToolbar}} 
                componentsProps={{toolbar:{showQuickFilter:true}}}
                pageSize={50} 
                rows={props.data} 
                columns={columns} 
                getRowId={row => row.idGuid} 
            />
        </Box>
    );
}
