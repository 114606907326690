import axios from 'axios';
import {useState, useEffect, useContext} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";

import Loading from '../Loading';
import Error from '../Error';

import { useSessionContext } from '../../contexts/SessionContext';

export default function ReelsEditTrackerList(props) {
    //console.debug("RTS Break ReelsEditTrackerList");

    const {userAuthToken, apiRoot} = useSessionContext();

    const [tracker, setTracker] = useState(props.tracker);
    const [repoTrackers, setRepoTrackers] = useState([]);

    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);


    function fetchData(){
        let apiURL = apiRoot + "/Trackers/GetTrackersByStatus/1";
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setRepoTrackers(response.data);
            }
            setIsLoading(false);
            setHasError(false);
        })
        .catch(error => {
            setIsLoading(false);
            console.log("RTS Data Error", error);
            setHasError(true);
        });
    }

    
    useEffect(() => {
        fetchData();
    }, []); 

    
    function valChange(e, val){
        props.setTrackerFx(val);
        setTracker(val);
    }

    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }

    return(
        <>
            <Autocomplete
                options={repoTrackers}
                renderInput={(params) => <TextField {...params} label="Tracker" margin="normal" size="small" />}
                value={tracker}
                onChange={valChange}
                getOptionLabel={(repoValue) => repoValue!=''?repoValue.esn:''}
                renderOption={(props, repoTrackers) => (
                    <Box component="li" {...props} key={repoTrackers.idGuid}>
                        {repoTrackers.esn}
                    </Box>
                )}
                isOptionEqualToValue={(option, value) => option.idGuid === value.idGuid}
            />
        </>
    );
}

