import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useSessionContext } from '../../contexts/SessionContext';


export default function NavButAdmin( props ){
   console.debug("RTS Break NavButAdmin");

    const history = useNavigate();
    const [open, setOpen] = useState(false);
    const [anchorElAdmin, setAnchorElAdmin] = useState(null);
    const {sessionCustomer} = useSessionContext();

    const handleOpenAdminMenu = (event) => {
        setOpen(!open);
        setAnchorElAdmin(event.currentTarget);
    };
      
    const handleCloseAdminMenu = () => {
        props.setOpenDrawerFx(false);
        setAnchorElAdmin(null);
    };


    function handleProductAliasClick(e){
        props.setOpenDrawerFx(false);
        handleCloseAdminMenu();
        history("/ProductAlias");
    }


    function handleNamedLocationsClick(e){
        props.setOpenDrawerFx(false);
        handleCloseAdminMenu();
        history("/NamedLocations");
    } 


    function handleUserRequestsClick(e){
        props.setOpenDrawerFx(false);
        handleCloseAdminMenu();
        history("/UserRequests");
    }
    

    function handleCustomerDetailsClick(e){
        props.setOpenDrawerFx(false);
        handleCloseAdminMenu();
        history("/AdminCustomerEdit", {state:{data:sessionCustomer.customer, mode:1}});
    }          


    if(sessionCustomer.accessLevel < 75){
        return("");
    }


    if(props.mode==="NavDrawer"){
        return (
            <>
            <ListItem button key="Administrator" onClick={handleOpenAdminMenu}>
                <ListItemText primary="Administration" />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem button key="Alias" sx={{ pl: 4 }}  onClick={handleProductAliasClick}>
                        <ListItemText primary="Customer Part Numbers" />
                    </ListItem>
                    <ListItem button key="NamedLocations" sx={{ pl: 4 }}  onClick={handleNamedLocationsClick}>
                        <ListItemText primary="Named Locations" />
                    </ListItem>                    
                    <ListItem button key="UserRequests" sx={{ pl: 4 }}  onClick={handleUserRequestsClick}>
                        <ListItemText primary="New User Request" />
                    </ListItem>
                    <ListItem button key="CustomerDetails" sx={{ pl: 4 }}  onClick={handleCustomerDetailsClick}>
                        <ListItemText primary="Customer Details" />
                    </ListItem>                                           
                </List>
            </Collapse>

            </>
        )
    }
    
    return(
        <>
        <Button
            key='Administration'
            onClick={handleOpenAdminMenu}
            sx={{ my: 2, mx: 1, color: 'white', textTransform: 'unset' }}
            endIcon={<ArrowDropDownIcon />}
        >
            Administration
        </Button>

        <Menu
            id="menu-admin"
            anchorEl={anchorElAdmin}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            open={Boolean(anchorElAdmin)}
            onClose={handleCloseAdminMenu}
            sx={{
                display: { xs: 'none', md: 'block' },
            }}
        >
            <MenuItem key='ProductAlias' onClick={handleProductAliasClick}>
                <Typography textAlign="center">Customer Parts</Typography>
            </MenuItem>
            <MenuItem key='NamedLocations' onClick={handleNamedLocationsClick}>
                <Typography textAlign="Left">Named Locations</Typography>
            </MenuItem>             
            <MenuItem key='UserRequests' onClick={handleUserRequestsClick}>
                <Typography textAlign="center">New User Request</Typography>
            </MenuItem>            
            <MenuItem key='CustomerDetails' onClick={handleCustomerDetailsClick}>
                <Typography textAlign="center">Customer Details</Typography>
            </MenuItem>
        </Menu>
        </>
    );
}



