import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';

import ReelsEdit from '../../components/Reels/ReelsEdit';
import ReelsInspectionList from '../../components/Reels/ReelsInspectionList';
import ReelsLoadList from '../../components/Reels/ReelsLoadList';


export default function ReelsPageEdit( props ){
    //console.debug("RTS Break ReelsPageEdit");

    const [tabIndex, setTabIndex] = useState(0);

    useEffect(() => {
        props.setIsModalFx(true);
    }, []); 

    const handleChange = (e, curIndex) => {
        setTabIndex(curIndex);
    };    

    return(
        <Container component="main" maxWidth="sm" sx={{mb:5}}>
            <CssBaseline />
            <Typography variant="h5" sx={{my:2}}>Edit Reel</Typography>

            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb:1 }}>
                <Tabs value={tabIndex} onChange={handleChange}>
                    <Tab label="Overview" value={0}  />
                    <Tab label="Inspections" value={1} />
                    <Tab label="Loads" value={2} />
                </Tabs>
            </Box>

            {
            tabIndex === 1
            ?
                <ReelsInspectionList />
            :
                tabIndex === 2
                ?
                    <ReelsLoadList />
                :
                <>
                <Box>
                    <ReelsEdit />
                </Box>
                </>
            }
      </Container>
    );    
}
