import axios from 'axios';
import {useState, useEffect, useContext} from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import Loading from '../../components/Loading';
import Error from '../../components/Error';
import ManuReelsList from '../../components/ManuReels/ManuReelsList';
import ManuReelsHeader from '../../components/ManuReels/ManuReelsHeader';
import HelpListOverview from '../../components/ManuReels/HelpListOverview';

import { useSessionContext } from '../../contexts/SessionContext';

export default function ManuReelsPage( props ){
    //console.debug("RTS Break ManuReelsPage");

    const {userAuthToken, apiRoot} = useSessionContext();

    const [helpListOverview, setHelpListOverview] = useState(false);

    const [data, setData] = useState([]);
    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    

    function fetchData(){
        let apiURL = apiRoot + "/Reels/GetAllReelsFull";
        axios
        .get(
            apiURL,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token }}
        )
        .then(response => {
            if (response.data) {
                setIsLoading(false);
                setData(response.data);
            }
        })
        .catch(error => {
            setHasError(true);
            console.log("RTS Data Error", error);
        });
    }
    
    useEffect(() => {
        props.setIsModalFx(false);
        fetchData();
    }, []);  
    

    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }

    return(
        <>
        <Dialog open={helpListOverview} onClose={()=>{setHelpListOverview(false);}}>
            <DialogTitle>Reels Help</DialogTitle>
            <DialogContent>
                <HelpListOverview />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => {setHelpListOverview(false);}}>Close</Button>
            </DialogActions>
        </Dialog>         
        <Box id="pageBox">
            <ManuReelsHeader setHelpListOverviewFx={setHelpListOverview} />
            <Box sx={{mt:2}}>           
                <ManuReelsList data={data} />
            </Box>
        </Box>
        </>
    );
}
