import axios from 'axios';
import {useState, useEffect} from 'react';
import {useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';


import EditIcon from '@mui/icons-material/Edit'; 

import Loading from '../Loading';
import Error from '../Error';

import { useSessionContext } from '../../contexts/SessionContext';


export default function ReelsInspectionList(props){
    //console.debug("RTS Break ReelsInspectionList");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();
    const history = useNavigate();
    const location = useLocation();
    const data = location.state.data;

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [repoInspections, setRepoInspections] = useState([]);

    function fetchData(){

        let apiObj = data;

        let apiURL = apiRoot + "/ReelInspections/GetReelInspectionsByReel";
        axios
        .post(
            apiURL,
            apiObj,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token}}
        )
        .then(response => {
            if (response.data) {
                setRepoInspections(response.data);
                setIsLoading(false);
            }
        })
        .catch(error => {
            console.log("RTS Data Error", error);
            setHasError(true);
        });
    }


    useEffect(() => {
        fetchData();
    }, []); 


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }


    function handleClick(e, cellValues){
        history("/ReelInsepctionsPageEdit", {state:{data:cellValues.row, mode:1}});
    }


    const columns = [
        {
            field: '',
            description:"Edit Inspection", 
            flex:1,
            maxWidth:60,
            renderCell: (cellValues) => {
                return (
                    <Link onClick={(event) => {handleClick(event, cellValues);}} sx={{textDecoration:'none', cursor:'pointer'}}>
                        <Box sx={{display:'flex'}}>
                            <EditIcon />
                        </Box>
                    </Link>
                );
            },
            align:"center",
            sortable:false,
            filterable:false,
            hideable:false,
            disableColumnMenu:true
        },
        {
            field: 'createDate', 
            headerName: "Inspection Date", 
            description:"Inspection Date", 
            flex:2,           
            valueFormatter: (parms) => {
                if(parms.value == null){
                    return '';
                }

                return (
                    format(parseISO(parms.value), "MM-dd-yyyy")
                );
            } 
        },
        {
            field: 'inspector', 
            headerName: "Inspector", 
            description:"Reel Inspector", 
            flex:3,
            valueGetter: (cellValues) => {
                return cellValues.row.appUser !== null?cellValues.row.appUser.firstName + ' ' + cellValues.row.appUser.lastName:null;
            },             
        },
        {
            field: 'inspType', 
            headerName: "Type", 
            description:"Inspection Type", 
            flex:3,
            valueGetter: (cellValues) => {
                return cellValues.row.inspectionType===100?'Final Inspection':'Damage Inspection';
            },             
        },                    
    ];

    return (
        <Box id="pageBox" sx={{width:'100%'}}>
            <DataGridPro 
                autoHeight 
                pagination 
                components={{Toolbar: GridToolbar}} 
                pageSize={50} 
                rows={repoInspections} 
                columns={columns} 
                getRowId={row => row.idGuid} 
            />
        </Box>
    );
}
