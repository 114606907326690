import axios from 'axios';
import {useState, useEffect} from 'react';
import {useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';

import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';

import EditCancel from '../EditCancel';
import Loading from '../Loading';
import Error from '../Error';

import { useSessionContext } from '../../contexts/SessionContext';

export default function AdminCustomerUserList(props){
    //console.debug("RTS Break AdminCustomerUserList");

    const {user, sessionCustomer, userAuthToken, apiRoot} = useSessionContext();

    const location = useLocation();
    const data = location.state.data;

    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [repoUsers, setRepoUsers] = useState([]);

    function fetchData(){

        let apiObj = data;

        let apiURL = apiRoot + "/Customers/GetCustomerUsers";
        axios
        .post(
            apiURL,
            apiObj,
            {headers:{'Authorization': 'Bearer ' + userAuthToken.token}}
        )
        .then(response => {
            if (response.data) {
                setRepoUsers(response.data);
                setIsLoading(false);
            }
        })
        .catch(error => {
            console.log("RTS Data Error", error);
            setHasError(true);
        });
    }


    useEffect(() => {
        fetchData();
    }, []); 


    if(hasError){
        return(
            <Error />
        );
    }


    if(isLoading){
        return(
            <Loading />
        );
    }


    const columns = [
        {
            field: 'firstName', 
            headerName: "First Name", 
            description:"User First Name", 
            flex:3
        },        
        {
            field: 'lastName', 
            headerName: "Last Name", 
            description:"User Last Name", 
            flex:3 
        },
        {
            field: 'email', 
            headerName: "Email", 
            description:"User Email", 
            flex:4 
        }        
    ];


    return (
        <Box sx={{width:'100%'}}>
            <DataGridPro 
                autoHeight 
                pagination 
                components={{Toolbar: GridToolbar}} 
                pageSize={50} 
                rows={repoUsers} 
                columns={columns} 
                getRowId={row => row.userName} 
            />

            <Box sx={{textAlign:'center', mt:3}}>
                <EditCancel />
            </Box>        
        </Box>
    );
}
